<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>가격 갱신</strong>
      </div>
      <b-form>
        <shop-preset class="mb-2" v-model="form.renew.shop" :currency="true"></shop-preset>
        <div class="mb-2">
          <small>발란코드</small><br/>
          <b-form-textarea id="goodsNo" :rows="6" v-model.trim="form.renew.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
          <small class="text-muted">콤마, 띄어쓰기, 탭, 엔터 등 숫자 이외의 모든 것으로 구분된 발란코드를 입력해주세요</small>
        </div>
      </b-form>
      <div class="mt-2 clearfix">
        <b-button class="m-1" variant="primary" @click="queryCount">갱신 대상 상품수 확인</b-button>
        <b-button class="m-1" variant="warning" @click="resetForm">초기화</b-button>
        <div class="pull-right">
          <b-button class="m-1" variant="outline-primary" @click="modal.help = true">도움말</b-button>
        </div>
      </div>
    </b-card>

    <div class="mb-2">
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="발란코드, shop_id" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
      </b-input-group>
    </div>

    <c-table :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="items.list.length + (hasMore.list ? '+' : '') + ' 개'" @btn-clicked="btnAction" @get-more="list(true)"></c-table>

    <b-card v-if="pid">
      <b-progress v-model="per" :max="100" show-progress></b-progress>
      <div v-for="log in prgLog">
        <small>{{ log }}</small>
      </div>
    </b-card>

    <b-modal title="도움말" v-model="modal.help" ok-only ok-title="확인">
      - processing, registered 상태의 상품들이 대상입니다<br/>
    </b-modal>

    <b-modal title="가격 변경" v-model="modal.process" ok-only ok-variant="secondary" ok-title="닫기">
      <div v-if="busy.count" class="text-center">
        <b-spinner variant="info"></b-spinner>
      </div>
      <div v-else class="text-center">
        {{ count }} 개의 상품이 대상입니다
        <div v-if="count === 0">
          검색조건을 확인해주세요.
        </div>
        <b-button v-else class="mt-3" variant="primary" block @click="renewPrice" :disabled="busy.renew">
          가격 변경 시작
          <b-spinner class="ml-1" v-if="busy.renew" small></b-spinner>
        </b-button>
      </div>
      <div class="mt-5">
        <small>가격 갱신 예약</small>
        <div>
          <c-switch v-model="form.renew.isReserved" class="mt-2 switch-3d " color="primary" label />
        </div>

        <div v-if="form.renew.isReserved">
          <small>시작시각</small>
          <b-row>
            <b-col cols="8">
              <b-form-datepicker v-model="form.renew.reservedDate" locale="kr"></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker v-model="form.renew.reservedTime" locale="kr" minutes-step="10"></b-form-timepicker>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <b-modal title="예약 확인 / 수정" v-model="modal.reserved">
      <div>
        <c-switch v-model="form.renew.isReserved" class="mt-2 switch-3d " color="primary" label />
      </div>

      <div v-if="form.renew.isReserved">
        <small>시작시각</small>
        <b-row>
          <b-col cols="8">
            <b-form-datepicker v-model="form.renew.reservedDate" locale="kr"></b-form-datepicker>
          </b-col>
          <b-col>
            <b-form-timepicker v-model="form.renew.reservedTime" locale="kr" minutes-step="10"></b-form-timepicker>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {Switch as cSwitch} from '@coreui/vue'

export default {
  name: 'RenewPrice',
  title: '가격 갱신',
  components: {cSwitch},
  data() {
    return {
      defaultForm: {
        renew: {
          shop: [],
          goods_no: '',
          isReserved: false,
          reservedDate: this.$utils.kstD(),
          reservedTime: '03:00:00'
        },
        list: {
          search: '',
          limit: 100,
          skip: 0,
        },
      },
      form: {
        renew: {},
        list: {},
      },
      count: 0,
      pid: '',
      prgLog: [],
      per: 0,
      done: false,
      modal: {help: false, process: false, reserved: false},
      busy: {count: false, renew: false, list: false, listmore: false},

      perPage: 20,
      lastBody: {list: {}},
      items: {list: []},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      fields: [
        // 'selected',
        {key: 'html1', label: '실행시각', class: 'text-center'},
        {key: 'html4', label: '예약시각', class: 'text-center'},
        {key: 'html2', label: '실행자', class: 'text-center'},
        {key: 'html3', label: '상품필터', class: 'text-center'},
        {key: 'cnt', label: '상품수', class: 'text-center'},
        {key: 'success', label: '성공', class: 'text-center'},
        {key: 'error', label: '에러', class: 'text-center'},
        {key: 'remain', label: '남음', class: 'text-center'},
        {key: '_actions', label: '상세', style: {textAlign: 'center'}, buttons: [
            {label: '상세', event: 'show_modal'},
            {label: '작업 삭제', variant: 'outline-primary', event: 'delete', if: e => e.isReserved && this.$utils.kstDHM() < e.reservedDt}
          ]},
      ],
    }
  },
  async created() {
    this.form.renew = this.$utils.clone(this.defaultForm.renew);
    this.form.list = this.$utils.clone(this.defaultForm.list);
  },
  mounted() {
    this.list().then();
    this.$socket.on('progress', (pid, type, desc, per) => {
      if (per !== undefined) {
        // console.log('progress', pid, type, desc, per);
        this.per = per;
        if (per === 100) {
          delete this.$S.pricePid;
          // this.pid = '';
        }
      } else {
        // console.log('progress', pid, type, desc);
      }
      // this.prgLog.push(desc);
    });
    // this.$socket.on('stat', (...arr) => {
    //   console.log(...arr);
    // });
    // this.$socket.emit('join', '910872e7-2867-48cb-879f-5cc83257c231');
  },
  beforeDestroy() {
    this.$socket.off('progress');
    // this.$socket.off('stat');
  },
  methods: {
    resetForm() {
      this.form.renew = this.$utils.clone(this.defaultForm.renew);
    },
    getForm() {
      const form = this.form.renew;
      const shop_id = form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      const goodsNos = form.goods_no.trim() !== '' ? form.goods_no.split(/\D+/g).map(e => +e) : [];

      const cnt = shop_id.length + goodsNos.length;
      if (cnt === 0) {
        alert('하나 이상의 조건을 선택해주세요');
        return null;
      }

      const requestDoc = {
        shop_id,
        goodsNos,
        isReserved: form.isReserved,
      }

      if (form.isReserved) {
        if (!/\d{4}-\d{2}-\d{2}/.test(form.reservedDate)) {
          alert('입력한 날짜의 형태가 이상합니다.');
          return null;
        }
        if (!/\d{2}:\d{2}:\d{2}/.test(form.reservedTime)) {
          alert('입력한 시간의 형태가 이상합니다.');
          return null;
        }

        requestDoc.reservedDt = `${form.reservedDate} ${form.reservedTime}`;
      }
      return requestDoc;
    },
    async queryCount() {
      const form = this.getForm();
      if (!form) return;
      this.busy.count = true;
      this.form.renew.rreservedDate = this.$utils.kstD();
      this.form.renew.reservedTime = this.$moment().add(10 - (new Date()).getMinutes() % 10, 'minutes').format('HH:mm:00');
      this.modal.process = true;
      const j = await this.$api.postJson('/price/renewPriceAll', {...form, returnCount: true});
      if (j) {
        this.count = j.cnt;
      }
      this.busy.count = false;
    },
    async renewPrice() {
      this.prgLog = [];
      const form = this.getForm();
      if (!form) return;
      if (this.$utils.kstDHM() >= form.reservedDt) {
        return alert(`가격 갱신 예약을 현재 시간보다 전으로 설정할 수 없습니다.`);
      }

      this.busy.renew = true;
      const j = await this.$api.postJson('/price/renewPriceAll', form);
      this.busy.renew = false;
      this.modal.process = false;
      if (j) {
        this.$modal.show({title: '가격갱신 시작', html: '가격갱신이 백그라운드에서 시작되었습니다. 아래의 결과를 확인해주세요.'});
      }
    },
    async list(more) {
      return this.$api.postTable(this, '/price/renewPriceLog', this.form.list, {more, fnAssign: this.assignTableData});
    },
    async delete(row) {
      const res = await this.$api.postJson('/price/renewPriceDelete', {
        _id: row._id
      });
      if (res.ok) {
        alert(res.msg)
      }
      await this.list();
    },
    assignTableData(e) {
      e.html1 = e.signature._dt;
      e.html2 = e.signature._name;
      e.html3 = [
        e.body.shop_id && e.body.shop_id.length ? (`SHOP: ${e.body.shop_id.length > 5 ?
          `${e.body.shop_id.slice(0, 5)} 외 ${e.body.shop_id.length - 5} 개` :
          e.body.shop_id.join(', ')}`) : '',
        e.body.goodsNos && e.body.goodsNos.length ? (`발란코드: ${e.body.goodsNos.length > 5 ?
          `${e.body.goodsNos.slice(0, 5)} 외 ${e.body.goodsNos.length - 5} 개` :
          e.body.goodsNos.join(', ')}`) : ''
      ].filter(e => e).join('<br/>');
      e.html4 = e.reservedDt;
      e.remain = e.cnt - (e.success || 0) - (e.error || 0);
    },
    async btnAction(row, event) {
      if (event === 'show_modal') {
        this.$modal.show({type: 'json', item: row.item});
      } else if (event === 'delete') {
        if (!confirm('예약된 작업을 정말 삭제하시겠습니까?')) return;
         await this.delete(row.item)
      }
    },
  }
}
</script>
